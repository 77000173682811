<template>
  <div class="verification">
    <div class="verification-header">
      <logo />
    </div>
    <div class="verification-content">
      <p style="text-align: center;">
        To continue registration please wait...
      </p>
    </div>

    <div class="verification-footer">
      If you have technical issues please contact our support at <a :href="'mailto:' + settings.email_support">{{ settings.email_support }}</a>
    </div>
  </div>
</template>

<script>
import Logo from "@/assets/images/Logo";
import {mapState} from "vuex";

export default {
  name: 'VerificationComplete',
  components: {
    Logo
  },
  props: {
    token: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapState({
      settings: state => state.init.settings,
      registrationHash: state => state.registration.hash,
      registrationType: state => state.registration.type
    })
  },
  mounted() {
    if (this.token.length > 0) {
      this.$sdk.registration.verification.save(this.token).then(response => {
        if (Object.keys(response.headers).includes('x-id')) {
          const hash = response.headers['x-id']

          this.$store.dispatch('getRegistration', hash).then(() => {
            this.$router.push({
              name: 'ContinueRegistration',
              params: {
                hash: this.registrationHash
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="less" src="../../assets/css/verification.less"></style>
